import { useEffect, useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
// import Img from "gatsby-image"
// import { MDXRenderer } from "gatsby-plugin-mdx"
import { motion, useAnimation } from "framer-motion"

import Context from "../../context/"
import ContentWrapper from "../../styles/ContentWrapper"
// import Underlining from "../../styles/Underlining"
import SplashScreen from "../splashScreen"
import Theme from "../../styles/Theme"

import bridgeMp4 from "../../content/hero/bridge_side_5.mp4"
import towerGenerationWebM from "../../content/hero/towerGeneration.webm"

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.background};
  padding: 50px 25px 0 25px;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: calc(${({ theme }) => theme.headerHeight} + 50px) 25px 0 25px;
  }
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 50px;
    padding: 0 25px;
    video {
      grid-column: 1 / span 6;
      grid-row: 1;
      width: 100%;
    }
    div {
      grid-column: 4 / span 3;
      grid-row: 1;
      align-self: center;
      position: relative;
    }
    .title {
      margin: 0;
      svg.intro {
        width: 100%;
        height: 350px;
        // background: linear-gradient(135deg, #aa3bb1, #582a7e);
        // max-width: 800px;
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        // box-shadow: 0 30px 50px -20px rgb(46, 6, 66);
        .text {
          display: none;
        }
        &.go {
          .text {
            // font-family: Arial, sans-serif;
            // font-size: 20px;
            display: block;
          }
          .text-stroke {
            fill: none;
            stroke: #fff;
            stroke-width: 2.8px;
            stroke-dashoffset: 300;
            stroke-dasharray: 300;
            stroke-linecap: butt;
            stroke-linejoin: round;
            animation: dash 2.5s linear reverse forwards;
          }
          .text-stroke:nth-child(2) {
            animation-delay: 0.3s;
          }
          .text-stroke:nth-child(3) {
            animation-delay: 0.6s;
          }
          .text-stroke:nth-child(4) {
            animation-delay: 0.9s;
          }
          .text-stroke-2 {
            fill: none;
            animation: fill 0.9s linear forwards;
            animation-delay: 2.8s;
          }
          .text-stroke:nth-child(6) {
            animation-delay: 3.1s;
          }
          .text-stroke:nth-child(7) {
            animation-delay: 3.4s;
          }
          .text-stroke:nth-child(8) {
            animation-delay: 3.7s;
          }
        }
      }

      @keyframes dash {
        0% {
          stroke-dashoffset: 0;
        }
        100% {
          stroke-dashoffset: 300;
        }
      }
      @keyframes fill {
        0% {
          fill: none;
        }
        25% {
          fill: rgba(255, 255, 255, 0.25);
        }
        50% {
          fill: rgba(255, 255, 255, 0.5);
        }
        75% {
          fill: rgba(255, 255, 255, 0.75);
        }
        100% {
          fill: rgba(255, 255, 255, 1);
        }
      }
    }
    .subtitle {
      font-size: 1.125rem;
      line-height: 1.5rem;
      margin: 0;
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding: 0;
      display: flex;
      flex-direction: column;
    }
  }
`

// const AnimatedUnderlining = motion.custom(Underlining)

const Hero = ({ content }) => {
  const { frontmatter } = content[0].node
  const { isIntroDone } = useContext(Context).state

  // Controls to orchestrate animations of greetings, emoji, social profiles, underlining
  const gControls = useAnimation()

  // Start Animations after the splashScreen sequence is done
  useEffect(() => {
    const pageLoadSequence = async () => {
      if (isIntroDone) {
        await gControls.start({
          opacity: 1,
          y: 0,
          transition: { delay: 0.4 },
        })
      }
    }
    pageLoadSequence()
  }, [isIntroDone, gControls])

  return (
    <StyledSection id="hero">
      {!isIntroDone && <SplashScreen />}
      <StyledContentWrapper>
        <video autoPlay loop muted>
          <source src={bridgeMp4} type="video/mp4" />
          <source src={towerGenerationWebM} type="video/webm" />
        </video>
        <motion.div initial={{ opacity: 0, y: 20 }} animate={gControls}>
          <h2 className="subtitle">{frontmatter.subtitle}</h2>
          <h1 className="title">
            <svg className="intro go" viewBox="0 0 765 400">
              <text
                textAnchor="start"
                x="0"
                y="75"
                className="text text-stroke"
                clipPath="url(#text1)"
              >
                Architecture,
              </text>
              <text
                textAnchor="start"
                x="0"
                y="150"
                className="text text-stroke"
                clipPath="url(#text2)"
              >
                Computational
              </text>
              <text
                textAnchor="start"
                x="0"
                y="225"
                className="text text-stroke"
                clipPath="url(#text3)"
              >
                Design and
              </text>
              <text
                textAnchor="start"
                x="0"
                y="300"
                className="text text-stroke"
                clipPath="url(#text4)"
              >
                Fabrication
              </text>
              <text
                textAnchor="start"
                x="0"
                y="75"
                className="text text-stroke text-stroke-2"
                clipPath="url(#text1)"
              >
                Architecture,
              </text>
              <text
                textAnchor="start"
                x="0"
                y="150"
                className="text text-stroke text-stroke-2"
                clipPath="url(#text2)"
              >
                Computational
              </text>
              <text
                textAnchor="start"
                x="0"
                y="225"
                className="text text-stroke text-stroke-2"
                clipPath="url(#text3)"
              >
                Design and
              </text>
              <text
                textAnchor="start"
                x="0"
                y="300"
                className="text text-stroke text-stroke-2"
                clipPath="url(#text4)"
              >
                Fabrication
              </text>
              <defs>
                <clipPath id="text1">
                  <text textAnchor="start" x="0" y="75" className="text">
                    Architecture,
                  </text>
                </clipPath>
                <clipPath id="text2">
                  <text textAnchor="start" x="0" y="150" className="text">
                    Computational
                  </text>
                </clipPath>
                <clipPath id="text3">
                  <text textAnchor="start" x="0" y="225" className="text">
                    Design and
                  </text>
                </clipPath>
                <clipPath id="text4">
                  <text textAnchor="start" x="0" y="300" className="text">
                    Fabrication
                  </text>
                </clipPath>
              </defs>
            </svg>
          </h1>
        </motion.div>
      </StyledContentWrapper>
    </StyledSection>
  )
}

Hero.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        body: PropTypes.string.isRequired,
        frontmatter: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default Hero

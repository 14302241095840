import { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import { motion, useAnimation } from "framer-motion"

// import { detectMobileAndTablet, isSSR } from "../../utils"
import { useOnScreen } from "../../hooks"
import ContentWrapper from "../../styles/ContentWrapper"

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.background};
  padding: 100px 25px 0 25px;
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 25px;
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding: 0;
    }
  }
`

const StyledWork = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-auto-flow: row;
  grid-gap: 50px;
  /* Workaround: https://stackoverflow.com/questions/38993170/last-margin-padding-collapsing-in-flexbox-grid-layout */
  &::after {
    content: "";
    width: ${({ itemCount }) =>
      Math.ceil(itemCount / 2) % 2 === 1 ? "17.125rem" : "2.5rem"};
  }
  h5,
  h6 {
    font-size: 1.25rem;
    line-height: 1.375rem;
  }
  h6 {
    color: ${({ theme }) => theme.colors.textSecondary};
  }
  .work {
    grid-column-end: span 2;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
    > div,
    a {
      display: block;
      width: calc(50% - 25px);
      position: relative;
      align-self: flex-start;
      :hover span {
        background-color: rgba(0, 0, 255, 0.8);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transition: all 0.2s;
      }
    }
    h3 {
      width: 100%;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: flex;
    grid-template-columns: unset;
    grid-gap: 50px;
    flex-direction: column;
    .work {
      flex-direction: column;
      grid-gap: 25px;
      > div,
      a {
        width: 100%;
      }
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-auto-flow: row;
    grid-template-columns: repeat(4, 1fr);
    overflow: visible;
    padding: 0;
  }
`

const Work = ({ content }) => {
  const { exports, frontmatter } = content[0].node
  const { work } = exports

  const ref = useRef()
  const onScreen = useOnScreen(ref)

  const iControls = useAnimation()
  const bControls = useAnimation()

  // useEffect(() => {
  //   // If mobile or tablet, show all work initially
  //   // Otherwise work.mdx will determine how many work are shown
  //   // (isSSR) is used to prevent error during gatsby build
  //   if (!isSSR && detectMobileAndTablet(window.innerWidth)) {
  //     setShownWork(work.length)
  //   }
  // }, [work])

  useEffect(() => {
    const sequence = async () => {
      if (onScreen) {
        // i receives the value of the custom prop - can be used to stagger
        // the animation of each "work" element
        await iControls.start((i) => ({
          opacity: 1,
          scaleY: 1,
          transition: { delay: i * 0.1 },
        }))
        await bControls.start({ opacity: 1, scaleY: 1 })
      }
    }
    sequence()
  }, [onScreen, iControls, bControls])

  return (
    <StyledSection id="work">
      <StyledContentWrapper>
        <h2 className="section-title">{frontmatter.title}</h2>
        <StyledWork itemCount={work.length} ref={ref}>
          {work.map(({ title, description, type, date, image }, key) => (
            <motion.div className="work" key={key}>
              <h3>{title}</h3>
              <Link to={title.toLowerCase()} aria-label={title}>
                <Img fluid={image.childImageSharp.fluid} />
                <span></span>
              </Link>
              <div>
                <h5>{description}</h5>
                <h6>Type:</h6>
                <h5>{type}</h5>
                <h6>Date: </h6>
                <h5>{date}</h5>
              </div>
            </motion.div>
          ))}
        </StyledWork>
      </StyledContentWrapper>
    </StyledSection>
  )
}

Work.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        exports: PropTypes.shape({
          work: PropTypes.array.isRequired,
        }).isRequired,
        frontmatter: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default Work

import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/sections/hero"
import Work from "../components/sections/work"
import Projects from "../components/sections/projects"
import Bio from "../components/sections/bio"
import Publications from "../components/sections/publications"
import Contact from "../components/sections/contact"
import { splashScreen } from "../config"

const IndexPage = ({ data }) => (
  <Layout splashScreen={splashScreen}>
    <SEO title="Evangelos Pantazis" />
    <Hero content={data.hero.edges} />
    <Work content={data.work.edges} />
    <Projects content={data.projects.edges} />
    <Bio content={data.bio.edges} />
    <Publications content={data.publications.edges} />
    <Contact content={data.contact.edges} />
  </Layout>
)

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default IndexPage

export const pageQuery = graphql`
  {
    hero: allMdx(filter: { fileAbsolutePath: { regex: "//hero//" } }) {
      edges {
        node {
          body
          frontmatter {
            title
            subtitle
          }
        }
      }
    }
    work: allMdx(filter: { fileAbsolutePath: { regex: "//work//" } }) {
      edges {
        node {
          exports {
            work {
              title
              description
              type
              date
              image {
                childImageSharp {
                  fluid(maxWidth: 300, maxHeight: 300, quality: 90) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          frontmatter {
            title
          }
        }
      }
    }
    projects: allMdx(
      filter: {
        fileAbsolutePath: { regex: "//projects//" }
        frontmatter: { visible: { eq: "true" } }
      }
      sort: { fields: [frontmatter___position], order: ASC }
    ) {
      edges {
        node {
          fm: frontmatter {
            title
            category
            tags
            date
            scale
            position
            featuredImage {
              childImageSharp {
                fluid(
                  maxWidth: 254
                  maxHeight: 254
                  quality: 90
                  traceSVG: { color: "rgb(0, 0, 255)" }
                ) {
                  tracedSVG
                  aspectRatio
                  src
                  srcSet
                  sizes
                }
              }
              publicURL
            }
          }
          fields {
            slug
          }
        }
      }
    }
    bio: allMdx(filter: { fileAbsolutePath: { regex: "//bio//" } }) {
      edges {
        node {
          exports {
            bio {
              title
              content
            }
          }
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 400, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            description
          }
        }
      }
    }
    publications: allMdx(
      filter: { fileAbsolutePath: { regex: "//publications//" } }
    ) {
      edges {
        node {
          exports {
            journalsAndBooks {
              title
              items {
                title
                link
              }
            }
            conferencePapers {
              title
              items {
                title
                link
              }
            }
          }
          frontmatter {
            title
          }
        }
      }
    }
    contact: allMdx(filter: { fileAbsolutePath: { regex: "//contact//" } }) {
      edges {
        node {
          exports {
            contact {
              title
              content
            }
          }
          frontmatter {
            title
            name
            address
            phone
            email
            map {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image"

import ContentWrapper from "../../styles/ContentWrapper"

// Full Width Section
const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.background};
  padding: 100px 25px 0 25px;
`

// Fixed width container for content stuff
const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 25px;
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding: 0;
    }
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  p {
    margin: 0;
  }
  a {
    color: ${({ theme }) => theme.colors.textSecondary};
    &:hover {
      color: ${({ theme }) => theme.colors.textPrimary};
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: flex;
    grid-template-columns: unset;
    grid-gap: 50px;
    flex-direction: column;
    ol {
      display: flex;
      flex-direction: column;
    }
  }
`

const ProfileImg = styled(Img)`
  align-self: flex-start;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    align-self: auto;
  }
`

const Description = styled.p`
  grid-column: 4 / span 3;
  padding-left: 25px;
  font-size: 1.25rem;
  line-height: 1.5rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding-left: 0;
  }
`

const Details = styled.div`
  padding-top: 25px;
  grid-column: 1 / span 6;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 50px;
  h4 {
    font-size: 1.25rem;
    line-height: 1.375rem;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding-top: 0;
    display: flex;
    flex-direction: column;
  }
`

const Bio = ({ content }) => {
  const { exports, frontmatter } = content[0].node
  const { bio } = exports

  return (
    <StyledSection id="bio">
      <StyledContentWrapper>
        <h2 className="section-title">{frontmatter.title}</h2>
        <Grid>
          <ProfileImg fluid={frontmatter.image.childImageSharp.fluid} />
          <Description>{frontmatter.description}</Description>
          <Details>
            {bio.map(({ title, content }, key) => (
              <div key={key}>
                <h4>{title}</h4>
                <p dangerouslySetInnerHTML={{ __html: content }} />
              </div>
            ))}
          </Details>
        </Grid>
      </StyledContentWrapper>
    </StyledSection>
  )
}

Bio.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        exports: PropTypes.shape({
          bio: PropTypes.array.isRequired,
        }).isRequired,
        frontmatter: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default Bio

import { useEffect, useLayoutEffect, useRef, useState } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import { useTransition, a } from "react-spring"
import { detectMobileAndTablet, isSSR, useMedia } from "../../utils/"

import ContentWrapper from "../../styles/ContentWrapper"

// Full Width Section
const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.background};
  padding: 100px 25px 0 25px;
`

// Fixed width container for content stuff
const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 25px;
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding: 0;
    }
  }
  .list {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 30px 0;
    h4 {
      font-weight: 400;
    }
    p {
      margin: 0;
    }
    a {
      display: block;
      position: relative;
      color: ${({ theme }) => theme.colors.textSecondary};
      &:hover {
        color: ${({ theme }) => theme.colors.textPrimary};
      }
    }
    .gif {
      width: 100%;
    }
    .project:hover .description {
      visibility: visible;
      height: 100%;
      opacity: 1;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .list > div {
    position: absolute;
    will-change: transform, width, height, opacity;
    padding: 25px;
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding: 0;
    }
  }

  .list > div > div {
    position: relative;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 10px;
    border-radius: 4px;
    box-shadow: 0px 10px 50px -10px rgba(0, 0, 0, 0.2);
  }
`

const SmallGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 50px;
  h2 {
    grid-column-end: span 6;
  }
  div {
    font-size: 0.875rem;
    :nth-child(2) {
      grid-column: 11 / span 1;
    }
  }
  button {
    background-color: inherit;
    color: white;
    padding: 5px 5px 5px 0;
    margin: 0;
    margin-top: 0px;
    cursor: pointer;
    border: inherit;
    :nth-child(2) {
      margin-top: 10px;
    }
    :hover {
      color: ${({ theme }) => theme.colors.textSecondary};
      transition: color 0.3s ease-in-out;
    }
    &.asc::after {
      content: "△";
      padding-left: 5px;
      position: absolute;
    }
    &.desc::after {
      content: "▽";
      padding-left: 5px;
      position: absolute;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 0;
    div {
      width: 50%;
    }
    button {
      padding: 10px 10px 10px 0;
    }
  }
`

const Description = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  background-color: ${({ theme, cat }) => theme.colors[cat.toLowerCase()]};
  color: #000000;
  visibility: hidden;
  opacity: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.2s, visibility 0.2s;
  strong {
    font-family: ${({ theme }) => theme.fonts.secondary};
  }
  h5 {
    color: ${({ theme }) => theme.colors.textSecondary};
    margin-bottom: 10px;
  }
  h6 {
    color: #000000;
  }
`

const Projects = ({ content }) => {
  const projects = content.map((p) => p.node)
  const refContainer = useRef()
  const [containerWidth, setContainerWidth] = useState(1820)
  const [windowWidth, setWindowWidth] = useState(0)
  const [visibleProjects, setVisibleProjects] = useState(projects)
  const [lastSorting, setLastSorting] = useState()

  useEffect(() => {
    let handleWindowSizeChange
    // if (isSSR) is necessary to prevent error during the gatsby build
    if (!isSSR) {
      handleWindowSizeChange = () => setWindowWidth(window.innerWidth)
      // set initial innerWidth when component mounts
      setWindowWidth(window.innerWidth)
    }
    // Add event listener to update windowWidth in state
    window.addEventListener("resize", handleWindowSizeChange)
    return () => window.removeEventListener("resize", handleWindowSizeChange)
  }, [windowWidth])

  // if (windowWidth !== 0 && detectMobileAndTablet(windowWidth)) {
  //   setVisibleProjects(
  //     projects.filter((p, i, a) => {
  //       for (let j of [0, 1, 2, 3, 4, 8, 5, 12, 13, 19, 6, 7, 15]) {
  //         if (i === j) return p
  //       }
  //     })
  //   )
  //   //0-5, 15-17, 19, 26-27, 29 in a sorted array
  // }

  useLayoutEffect(() => {
    if (refContainer.current) {
      setContainerWidth(refContainer.current.offsetWidth)
    }

    window.addEventListener(
      "resize",
      () => {
        setContainerWidth(
          ReactDOM.findDOMNode(refContainer.current).clientWidth
        )
      },
      false
    )
  }, [])

  const filterProjects = (type) => {
    type === "all"
      ? setVisibleProjects(projects)
      : setVisibleProjects(
          projects.filter((p) => p.fm.category.toLowerCase() === type)
        )
  }

  const [sortingArrows, setSortingArrows] = useState({
    category: "",
    date: "",
    tags: "",
    scale: "",
  })

  const sortProjects = (sort) => {
    if (lastSorting === sort) setVisibleProjects([...visibleProjects].reverse())
    else {
      setVisibleProjects(
        [...visibleProjects].sort((a, b) => {
          let x, y
          switch (sort) {
            case "category":
              x = a.fm[sort].toLowerCase()
              y = b.fm[sort].toLowerCase()
              break
            case "date":
              x = a.fm[sort].slice(-4)
              y = b.fm[sort].slice(-4)
              break
            case "tags":
              x = a.fm[sort][0].toLowerCase()
              y = b.fm[sort][0].toLowerCase()
              break
            case "scale":
              return a.fm[sort] - b.fm[sort]
            default:
          }
          if (x < y) {
            return -1
          }
          if (x > y) {
            return 1
          }
          return 0
        })
      )
      setLastSorting(sort)
    }
  }

  // const itemHeight = 260 + 85 // 350 divided by 2

  let itemHeight = useMedia(
    ["(min-width: 1440px)", "(min-width: 360px)"],
    [345, 430],
    345
  )

  // Hook1: Tie media queries to the number of columns
  let columns = useMedia(
    [
      "(min-width: 1440px)",
      "(min-width: 1000px)",
      "(min-width: 700px)",
      "(min-width: 450px)",
      "(min-width: 360px)",
    ],
    [6, 4, 3, 2, 1],
    6
  )

  columns = typeof window !== `undefined` ? columns : 6
  itemHeight = typeof window !== `undefined` ? itemHeight : 345

  // Form a grid of stacked items using width & columns we got from hooks 1 & 2
  let heights = new Array(columns).fill(0) // Each column gets a height starting with zero
  let gridItems = visibleProjects.map((child, i) => {
    const column = heights.indexOf(Math.min(...heights)) // Basic masonry-grid placing, puts tile into the smallest column using Math.min
    const xy = [
      (containerWidth / columns) * column,
      (heights[column] += itemHeight) - itemHeight,
    ] // X = container width / number of columns * column index, Y = it's just the height of the current column
    return {
      ...child,
      xy,
      width: containerWidth / columns,
      height: itemHeight,
    }
  })
  // Hook5: Turn the static grid values into animated transitions, any addition, removal or change will be animated
  const transitions = useTransition(
    gridItems,
    (item) => item.fm.title.toLowerCase().split(" ").join("-"),
    {
      from: ({ xy, width, height }) => ({
        xy,
        width,
        height,
        opacity: 0,
      }),
      enter: ({ xy, width, height }) => ({
        xy,
        width,
        height,
        opacity: 1,
      }),
      update: ({ xy, width, height }) => ({
        xy,
        width,
        height,
      }),
      leave: { height: 0, opacity: 0 },
      config: {
        mass: 5,
        tension: 500,
        friction: 100,
      },
      trail: gridItems.length,
    }
  )

  return (
    <StyledSection id="projects">
      <StyledContentWrapper>
        <SmallGrid>
          <h2 className="section-title">Projects</h2>
          <div>
            <strong>Filter by:</strong>
            <br />
            <br />
            <button type="button" onClick={() => filterProjects("all")}>
              Show all
            </button>
            <br />
            <button type="button" onClick={() => filterProjects("research")}>
              Research
            </button>
            <br />
            <button type="button" onClick={() => filterProjects("teaching")}>
              Teaching
            </button>
            <br />
            <button type="button" onClick={() => filterProjects("work")}>
              Work
            </button>
          </div>
          <div>
            <strong>Sort by:</strong>
            <br />
            <br />
            <button
              type="button"
              className={
                sortingArrows.category
                  ? sortingArrows.category === " △"
                    ? "asc"
                    : "desc"
                  : null
              }
              onClick={() => {
                sortProjects("category")
                setSortingArrows({
                  category: sortingArrows.category === " △" ? " ▽" : " △",
                  date: "",
                  tags: "",
                  scale: "",
                })
              }}
            >
              Category
            </button>
            <br />
            <button
              type="button"
              className={
                sortingArrows.date
                  ? sortingArrows.date === " △"
                    ? "asc"
                    : "desc"
                  : null
              }
              onClick={() => {
                sortProjects("date")
                setSortingArrows({
                  category: "",
                  date: sortingArrows.date === " △" ? " ▽" : " △", //" △"" ▽"
                  tags: "",
                  scale: "",
                })
              }}
            >
              Date
            </button>
            <br />
            <button
              type="button"
              className={
                sortingArrows.tags
                  ? sortingArrows.tags === " △"
                    ? "asc"
                    : "desc"
                  : null
              }
              onClick={() => {
                sortProjects("tags")
                setSortingArrows({
                  category: "",
                  date: "",
                  tags: sortingArrows.tags === " △" ? " ▽" : " △",
                  scale: "",
                })
              }}
            >
              Type
            </button>
            <br />
            <button
              type="button"
              className={
                sortingArrows.scale
                  ? sortingArrows.scale === " △"
                    ? "asc"
                    : "desc"
                  : null
              }
              onClick={() => {
                sortProjects("scale")
                setSortingArrows({
                  category: "",
                  date: "",
                  tags: "",
                  scale: sortingArrows.scale === " △" ? " ▽" : " △",
                })
              }}
            >
              Scale
            </button>
          </div>
        </SmallGrid>
        <div
          ref={refContainer}
          className="list"
          style={{ height: Math.max(...heights) }}
        >
          {transitions.map(
            ({ item: { fields, fm }, props: { xy, ...rest }, key }) => (
              <a.div
                key={key}
                style={{
                  transform: xy.interpolate(
                    (x, y) => `translate3d(${x}px,${y}px,0)`
                  ),
                  ...rest,
                }}
                className="project"
                id={fm.title.toLowerCase().split(" ").join("-")}
              >
                <Link to={fields.slug} aria-label={fm.title}>
                  {fm.featuredImage ? (
                    fm.featuredImage.childImageSharp ? (
                      <Img fluid={fm.featuredImage.childImageSharp.fluid} />
                    ) : (
                      <img
                        src={fm.featuredImage.publicURL}
                        alt=""
                        className="gif"
                      />
                    )
                  ) : null}
                  <Description className="description" cat={fm.category}>
                    <h5>
                      <strong>Tags:</strong>
                    </h5>
                    <h6>{`${fm.tags[0]}, ${fm.tags[1]}`}</h6>
                    <h5>
                      <strong>Date:</strong>
                    </h5>
                    <h6>{fm.date}</h6>
                  </Description>
                </Link>
                <h4>{fm.title}</h4>
              </a.div>
            )
          )}
        </div>
      </StyledContentWrapper>
    </StyledSection>
  )
}

Projects.propTypes = {
  content: PropTypes.array.isRequired,
}

export default Projects

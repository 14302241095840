import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image"
import { Default, Mobile } from "../../utils"
// import MapSVG from "../../content/contact/map.inline.svg"
import Icon1SVG from "../../content/contact/icon-01.inline.svg"
import Icon2SVG from "../../content/contact/icon-02.inline.svg"
import Icon3SVG from "../../content/contact/icon-03.inline.svg"
import Icon4SVG from "../../content/contact/icon-04.inline.svg"

import ContentWrapper from "../../styles/ContentWrapper"

// Full Width Section
const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.background};
  padding: 100px 25px 0 25px;
`

// Fixed width container for content stuff
const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 25px;
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding: 0;
    }
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 50px;
  #info0 {
    grid-column: 9 / span 1;
  }
  p {
    margin: 0;
    font-size: 0.875rem;
  }
  a {
    color: ${({ theme }) => theme.colors.textSecondary};
    &:hover {
      color: ${({ theme }) => theme.colors.textPrimary};
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: flex;
    flex-direction: column;
  }
`
const Name = styled.div`
  grid-column-start: 7;
  grid-column-end: span 2;
`
const Address = styled.div`
  grid-column-end: span 2;
`
const Info = styled.div`
  grid-column-end: span 2;
`

const Icon1 = styled(Icon1SVG)`
  width: 34px;
  stroke: #ffffff;
  stroke-miterlimit: 10;
`
const Icon2 = styled(Icon2SVG)`
  width: 34px;
  stroke: #ffffff;
  stroke-miterlimit: 10;
`
const Icon3 = styled(Icon3SVG)`
  width: 34px;
  stroke: #ffffff;
  stroke-miterlimit: 10;
`
const Icon4 = styled(Icon4SVG)`
  width: 34px;
  stroke: #ffffff;
  stroke-miterlimit: 10;
`

// const Map = styled(MapSVG)`
//   grid-column: 1 / span 6;
// `

const Map = styled(Img)`
  grid-column: 1 / span 12;
`

const Icon = ({ icon }) => {
  switch (icon) {
    case 1:
      return <Icon1 />
    case 2:
      return <Icon2 />
    case 3:
      return <Icon3 />
    case 4:
      return <Icon4 />
    default:
      return null
  }
}

const Contact = ({ content }) => {
  const { exports, frontmatter } = content[0].node
  const { contact } = exports

  return (
    <StyledSection id="contact">
      <StyledContentWrapper>
        <h2 className="section-title">{frontmatter.title}</h2>
        <Grid>
          <Name>
            {frontmatter.name}
            <div>
              <a href="/EPantazis_vcard.vcf">Download .vcf file</a>
            </div>
          </Name>
          <Address>{frontmatter.address}</Address>
          <Info>
            <a href={`tel:${frontmatter.phone}`}>{frontmatter.phone}</a>
            <div>
              <a href={`mailto:${frontmatter.email}`}>{frontmatter.email}</a>
            </div>
          </Info>
          <Map fluid={frontmatter.map.childImageSharp.fluid} />
          <Default>
            {contact.map(({ title, content }, key) => {
              return (
                <div id={`info${key}`} key={key}>
                  <Icon icon={key + 1} />
                  <br />
                  <br />
                  <strong>{title}</strong>
                  <br />
                  <br />
                  <p dangerouslySetInnerHTML={{ __html: content }} />
                </div>
              )
            })}
          </Default>
        </Grid>
      </StyledContentWrapper>
    </StyledSection>
  )
}

Icon.propTypes = {
  icon: PropTypes.number.isRequired,
}

Contact.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        exports: PropTypes.shape({
          contact: PropTypes.array.isRequired,
        }).isRequired,
        frontmatter: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default Contact

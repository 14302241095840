import PropTypes from "prop-types"
import styled from "styled-components"

import ContentWrapper from "../../styles/ContentWrapper"

// Full Width Section
const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.background};
  padding: 100px 25px 0 25px;
`

// Fixed width container for content stuff
const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 25px;
    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding: 0;
    }
  }
`

const StyledPublications = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 50px;
  h3 {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
  ol {
    grid-row: 2 / span 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, auto);
    grid-auto-flow: column;
    grid-gap: 50px;
    margin: 0;
    list-style-position: inside;
    padding: 50px 0;
  }
  a {
    color: ${({ theme }) => theme.colors.textSecondary};
    &:hover {
      color: ${({ theme }) => theme.colors.textPrimary};
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: flex;
    grid-template-columns: unset;
    flex-direction: column;
    ol {
      display: flex;
      flex-direction: column;
    }
  }
`

const StyledJournalsAndBooks = styled.div`
  grid-column: 1 / span 2;
  h3 {
    width: 50%;
  }
  ol {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, auto);
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    grid-column: unset;
    h3 {
      width: inherit;
    }
    ol {
      // display: flex;
    }
  }
`

const StyledConferencePapers = styled.div`
  grid-column: 3 / span 4;
  h3 {
    width: 25%;
  }
  ol {
    grid-template-columns: repeat(4, auto);
    grid-template-rows: repeat(4, auto);
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    grid-column: unset;
    h3 {
      width: inherit;
    }
    ol {
      // display: flex;
    }
  }
`

const Publications = ({ content }) => {
  const { exports, frontmatter } = content[0].node
  const { journalsAndBooks: jnb, conferencePapers: cp } = exports

  return (
    <StyledSection id="publications">
      <StyledContentWrapper>
        <h2 className="section-title">{frontmatter.title}</h2>
        <StyledPublications>
          <StyledJournalsAndBooks>
            <h3>{jnb.title}</h3>
            <ol>
              {jnb.items.map(({ title, link }, key) => (
                <li key={key}>
                  <br />
                  {title}
                  <br />
                  <a href={link}>[link]</a>
                </li>
              ))}
            </ol>
          </StyledJournalsAndBooks>
          <StyledConferencePapers>
            <h3>{cp.title}</h3>
            <ol>
              {cp.items.map(({ title, link }, key) => (
                <li key={key}>
                  <br />
                  {title}
                  <br />
                  <a href={link}>[link]</a>
                </li>
              ))}
            </ol>
          </StyledConferencePapers>
        </StyledPublications>
      </StyledContentWrapper>
    </StyledSection>
  )
}

Publications.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        exports: PropTypes.shape({
          journalsAndBooks: PropTypes.shape({
            title: PropTypes.string.isRequired,
            items: PropTypes.array.isRequired,
          }).isRequired,
          conferencePapers: PropTypes.shape({
            title: PropTypes.string.isRequired,
            items: PropTypes.array.isRequired,
          }).isRequired,
        }).isRequired,
        frontmatter: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default Publications
